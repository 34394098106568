import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ExternalHTMLContent,
  Section,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import { createRequiredValidator, Form } from '@ac/react-infrastructure';

import {
  Body,
  ComplementaryDetailsSection,
  Footer,
  Header,
  PersonalDetailsSection,
  ReservationDetailsSection,
  View,
} from 'components';
import { paths } from 'configs/paths';
import {
  cacheCheckedConsents,
  cacheEtd,
  cachePurposeOfStay,
  updateSummaryData,
} from 'store/electronicRegistrationProcess/actions';
import {
  getCachedAppliedVisibleConsents,
  getCachedEtd,
  getCachedPurposeOfStay,
  getIsPurchaseElementSelectionEnabled,
  getIsRegistrationCardCancelling,
  getPreferencesGroupOptions,
  getRegistrationCardDetails,
  getTermAndConditionOptions,
  getUniqueChosenPreferences,
} from 'store/electronicRegistrationProcess/selectors';
import { getPreferencesDiff } from 'store/electronicRegistrationProcess/utils/preferences/getPreferencesDiff';
import {
  getConsentsEntities,
  getCustomMessages,
  getGeneralSettings,
  getPropertyConfiguration,
  getPurposeOfStayEntities,
} from 'store/settings/selectors';
import { FormValidator } from 'utils/form';
import { useRouter, useScrollPositionRestoration } from 'utils/hooks';
import { PreferenceSection } from 'views/RegistrationCardSummary/components/PreferenceSection/PreferenceSection';

import { EtdField } from './components/EtdField/EtdField';
import { PurposeOfStay } from './components/PurposeOfStay/PurposeOfStay';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions';
import { preparePreferencesFormInitialValues } from './utils/preparePreferencesFormInitialValues';
import { prepareUpdateTermsAndConditionPayload } from './utils/prepareUpdateTermsAndConditionPayload';
import { FormProperties, FormValues } from './types';

import './RegistrationCardSummary.scss';

const BODY_ID = 'RegistrationCardSummaryBodyId';

export const RegistrationCardSummary = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    restoreScrollPosition,
    saveScrollPosition,
  } = useScrollPositionRestoration(`#${BODY_ID}`);

  const regCardDetails = useSelector(getRegistrationCardDetails);
  const isRegistrationCardCancelling = useSelector(
    getIsRegistrationCardCancelling
  );
  const visibleCachedAppliedConsents = useSelector(
    getCachedAppliedVisibleConsents
  );

  const preferencesGroups = useSelector(getPreferencesGroupOptions);
  const sortedPreferenceList = useMemo(
    () =>
      preferencesGroups.map((preferencesGroup) => ({
        ...preferencesGroup,
        preferences: preferencesGroup.preferences.sort(
          (preferenceA, preferenceB) =>
            preferenceA.isSelected === preferenceB.isSelected
              ? 0
              : preferenceA.isSelected
              ? -1
              : 1
        ),
      })),
    [preferencesGroups]
  );

  const uniqueChosenPreferences = useSelector(getUniqueChosenPreferences);

  const initialFormPreferenceOptionsValues = useMemo(() => {
    return preparePreferencesFormInitialValues(uniqueChosenPreferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const termAndConditionOptions = useSelector(getTermAndConditionOptions);

  const cachedPurposeOfStay = useSelector(getCachedPurposeOfStay);
  const cachedEtd = useSelector(getCachedEtd);
  const generalSettings = useSelector(getGeneralSettings);
  const customMessages = useSelector(getCustomMessages);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const purposesOfStay = useSelector(getPurposeOfStayEntities);
  const consents = useSelector(getConsentsEntities);
  const isPurchaseElementSelectionEnabled = useSelector(
    getIsPurchaseElementSelectionEnabled
  );

  const isComplementaryDetailsSectionVisible = Boolean(
    generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
  );

  const isPreferencesSectionOn = Boolean(generalSettings?.DISPLAY_PREFERENCES);

  const purposesOfStayOptions = useMemo(() => {
    return generalSettings?.DISPLAY_PURPOSE_OF_STAY && purposesOfStay;
  }, [generalSettings, purposesOfStay]);

  const validator = useRef(
    new FormValidator<FormValues>({
      purposeOfStay: purposesOfStayOptions
        ? createRequiredValidator('VALIDATION.ACTION_REQUIRED')
        : undefined,
    })
  );

  const redirectToNextPage = useCallback(() => {
    if (isPurchaseElementSelectionEnabled) {
      router.goTo(paths.REGISTRATION_CARD_PURCHASE_ELEMENTS);
    } else {
      router.goTo(paths.REGISTRATION_CARD_CONFIRMATION);
    }
  }, [isPurchaseElementSelectionEnabled, router]);

  const handleEditPersonalButtonClick = useCallback(() => {
    router.goTo(paths.REGISTRATION_CARD_EDIT_PERSONAL);
  }, [router]);

  const handleEditComplementaryButtonClick = useCallback(() => {
    router.goTo(paths.REGISTRATION_CARD_EDIT_COMPLEMENTARY_DETAILS);
  }, [router]);

  const handleDisclaimerLinkClick = useCallback(
    (link: string) => {
      saveScrollPosition();
      router.goTo(paths.EXTERNAL_LINK, {
        params: { link: encodeURIComponent(link) },
      });
    },
    [router, saveScrollPosition]
  );

  const handleConsentLinkClick = useCallback(
    (consentId: string) => {
      saveScrollPosition();
      router.goTo(paths.CONSENT, {
        params: {
          consentId,
        },
      });
    },
    [router, saveScrollPosition]
  );

  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const updateTermsAndConditionPayload = prepareUpdateTermsAndConditionPayload(
        values.termsAndCondition,
        regCardDetails?.profile.consents,
        consents,
        propertyConfiguration?.businessDate
      );

      const parsedEtd =
        values.etd &&
        `${regCardDetails?.reservation.departureDate}T${values.etd}`;

      const ifPurposeOfStayChanged =
        values.purposeOfStay !== regCardDetails?.reservation.purposeOfStay?.id;
      const idEtdChanged = parsedEtd !== regCardDetails?.reservation.etd;
      const ifConsentsChanged =
        updateTermsAndConditionPayload &&
        Object.keys(updateTermsAndConditionPayload).length;

      const profilePreferencesDiff = getPreferencesDiff(
        uniqueChosenPreferences,
        values.preferences
      );
      const reservationPreferencesDiff = getPreferencesDiff(
        uniqueChosenPreferences,
        values.preferences
      );

      const preferencesChanged =
        profilePreferencesDiff.changed || reservationPreferencesDiff.changed;

      if (
        ifPurposeOfStayChanged ||
        idEtdChanged ||
        ifConsentsChanged ||
        preferencesChanged
      ) {
        dispatch(
          updateSummaryData.trigger({
            purposeOfStayId: ifPurposeOfStayChanged
              ? values.purposeOfStay
              : undefined,
            etd: idEtdChanged ? parsedEtd : undefined,
            consents: ifConsentsChanged
              ? updateTermsAndConditionPayload
              : undefined,
            profilePreferencesDiff: profilePreferencesDiff.changed
              ? {
                  preferenceIds: {
                    set: profilePreferencesDiff.set,
                    remove: profilePreferencesDiff.remove,
                  },
                }
              : undefined,
            reservationPreferencesDiff: reservationPreferencesDiff.changed
              ? {
                  preferenceIds: {
                    set: reservationPreferencesDiff.set,
                    remove: reservationPreferencesDiff.remove,
                  },
                }
              : undefined,
            onSuccessfulUpdate: redirectToNextPage,
          })
        );
      } else {
        redirectToNextPage();
      }
    },
    [
      consents,
      dispatch,
      propertyConfiguration?.businessDate,
      redirectToNextPage,
      regCardDetails?.profile.consents,
      regCardDetails?.reservation.departureDate,
      regCardDetails?.reservation.etd,
      regCardDetails?.reservation.purposeOfStay?.id,
      uniqueChosenPreferences,
    ]
  );

  const cacheFormValues = (): void => {
    const { purposeOfStay, termsAndCondition, etd } = validator.current.data;

    if (purposeOfStay) {
      dispatch(cachePurposeOfStay(purposeOfStay));
    }

    if (termsAndCondition) {
      dispatch(cacheCheckedConsents(termsAndCondition));
    }

    if (etd) {
      dispatch(cacheEtd(etd));
    }
  };

  const scrollToInvalidSection = (): void => {
    if (validator.current.isValid) return;

    const firstInvalidSection =
      validator.current.statusesTree &&
      Object.entries(validator.current.statusesTree).find(([, value]) =>
        isDefined(value)
      )?.[0];

    if (firstInvalidSection) {
      const scrollableBody = document.getElementById(BODY_ID);
      const section = document.getElementById(firstInvalidSection);
      if (!scrollableBody || !section) return;

      scrollableBody.scrollTo({
        top: section.offsetTop - scrollableBody.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    restoreScrollPosition(true);

    return (): void => {
      cacheFormValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cacheFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <View>
      <Header title={t('REGISTRATION_CARD.TITLE')} />
      <Form
        initialValues={{
          [FormProperties.purposeOfStay]: cachedPurposeOfStay,
          [FormProperties.etd]: cachedEtd,
          [FormProperties.termsAndCondition]: visibleCachedAppliedConsents,
          [FormProperties.preferences]: initialFormPreferenceOptionsValues,
        }}
        validate={validator.current.validate}
        onSubmit={onFormSubmit}
        keepDirtyOnReinitialize
        destroyOnUnregister={false}
      >
        {(formRenderProps): JSX.Element => {
          const allCheckboxValues = formRenderProps.values.termsAndCondition
            ? Object.values(formRenderProps.values.termsAndCondition)
            : [];

          const isAllConsentsChecked =
            !allCheckboxValues.includes(false) &&
            allCheckboxValues.length === termAndConditionOptions.length;

          return (
            <>
              <Body id={BODY_ID}>
                {regCardDetails && (
                  <>
                    <ReservationDetailsSection
                      reservationData={regCardDetails.reservation}
                    />
                    <PersonalDetailsSection
                      cardDetails={regCardDetails}
                      editButtonVisible={generalSettings?.EDIT_MODE}
                      onEditButtonClick={handleEditPersonalButtonClick}
                      enableCollapsible
                    />
                  </>
                )}

                {isComplementaryDetailsSectionVisible && (
                  <ComplementaryDetailsSection
                    complementaryDetails={
                      regCardDetails?.reservation.reservationHeader
                    }
                    editButtonVisible={generalSettings?.EDIT_MODE}
                    onEditButtonClick={handleEditComplementaryButtonClick}
                  />
                )}

                {isPreferencesSectionOn && preferencesGroups.length ? (
                  <PreferenceSection preferenceList={sortedPreferenceList} />
                ) : null}

                <Section>
                  <div className="reg-card-summary-form-section">
                    <TermsAndConditions
                      id={FormProperties.termsAndCondition}
                      options={termAndConditionOptions}
                      onSelectAllClick={formRenderProps.form.change}
                      isAllChecked={isAllConsentsChecked}
                      className="reg-card-summary-form-section-item-large"
                      onConsentLinkClick={handleConsentLinkClick}
                    />
                    <div className="reg-card-summary-form-section-item-small">
                      <Text size={TextSize.xlg} className="spacing-bottom-sm">
                        {t('REGISTRATION_CARD.ADDITIONAL_DETAILS.TITLE')}
                      </Text>
                      {purposesOfStayOptions && (
                        <PurposeOfStay
                          id={FormProperties.purposeOfStay}
                          dataTestSelector="registration-summary-purpose-of-stay"
                          options={purposesOfStayOptions}
                          className="spacing-top-xlg"
                        />
                      )}
                      <EtdField
                        id={FormProperties.etd}
                        dataTestSelector="registration-summary-etd-field"
                        disabled={regCardDetails?.reservation.etaEtdGuaranteed}
                        className="spacing-top-xlg"
                      />
                    </div>
                  </div>
                  {customMessages?.DISCLAIMER && (
                    <div className="spacing-top-xlg spacing-bottom-xlg">
                      <ExternalHTMLContent
                        onLinkClick={handleDisclaimerLinkClick}
                        content={customMessages.DISCLAIMER}
                      />
                    </div>
                  )}
                </Section>
              </Body>

              <Footer
                hasPrimaryButton
                onPrimaryClick={(): void => {
                  formRenderProps.handleSubmit();
                  scrollToInvalidSection();
                }}
                hasCancelButton
                isRegistrationCardCancelling={isRegistrationCardCancelling}
              />
            </>
          );
        }}
      </Form>
    </View>
  );
};
